// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-accrosoft-ltd-vf-careers-site-theme-src-pages-404-js": () => import("./../../../node_modules/@accrosoft-ltd/vf-careers-site-theme/src/pages/404.js" /* webpackChunkName: "component---node-modules-accrosoft-ltd-vf-careers-site-theme-src-pages-404-js" */),
  "component---node-modules-accrosoft-ltd-vf-careers-site-theme-src-pages-careers-page-js": () => import("./../../../node_modules/@accrosoft-ltd/vf-careers-site-theme/src/pages/CareersPage.js" /* webpackChunkName: "component---node-modules-accrosoft-ltd-vf-careers-site-theme-src-pages-careers-page-js" */),
  "component---node-modules-accrosoft-ltd-vf-careers-site-theme-src-pages-job-alerts-index-js": () => import("./../../../node_modules/@accrosoft-ltd/vf-careers-site-theme/src/pages/job-alerts/index.js" /* webpackChunkName: "component---node-modules-accrosoft-ltd-vf-careers-site-theme-src-pages-job-alerts-index-js" */),
  "component---node-modules-accrosoft-ltd-vf-careers-site-theme-src-pages-job-alerts-optin-js": () => import("./../../../node_modules/@accrosoft-ltd/vf-careers-site-theme/src/pages/job-alerts/optin.js" /* webpackChunkName: "component---node-modules-accrosoft-ltd-vf-careers-site-theme-src-pages-job-alerts-optin-js" */),
  "component---node-modules-accrosoft-ltd-vf-careers-site-theme-src-pages-job-alerts-unsub-js": () => import("./../../../node_modules/@accrosoft-ltd/vf-careers-site-theme/src/pages/job-alerts/unsub.js" /* webpackChunkName: "component---node-modules-accrosoft-ltd-vf-careers-site-theme-src-pages-job-alerts-unsub-js" */),
  "component---node-modules-accrosoft-ltd-vf-careers-site-theme-src-pages-vacancies-js": () => import("./../../../node_modules/@accrosoft-ltd/vf-careers-site-theme/src/pages/vacancies.js" /* webpackChunkName: "component---node-modules-accrosoft-ltd-vf-careers-site-theme-src-pages-vacancies-js" */),
  "component---src-accrosoft-ltd-vf-careers-site-theme-templates-homepage-js": () => import("./../../../src/@accrosoft-ltd/vf-careers-site-theme/templates/Homepage.js" /* webpackChunkName: "component---src-accrosoft-ltd-vf-careers-site-theme-templates-homepage-js" */),
  "component---src-accrosoft-ltd-vf-careers-site-theme-templates-standard-page-template-js": () => import("./../../../src/@accrosoft-ltd/vf-careers-site-theme/templates/Standard Page Template.js" /* webpackChunkName: "component---src-accrosoft-ltd-vf-careers-site-theme-templates-standard-page-template-js" */)
}

